import React,{useState, useEffect, useLayoutEffect} from 'react'
import './meTree.css'
import {Get} from '../Shared/Api'


const TreeView = ({onChange, entity='Nodes', selected, layout=true}) => {

    const [loaded, Load] = useState(false)
    const [items, Items]= useState([])
    const [tree, Tree]= useState([])
    
    //alert(`selected ${selected}`)
    
    useEffect(() => {

        if(!loaded) {
            Get(`moshaf/${entity}/2`, Items, Load)
        }

        if(items?.length>0) {

            let course, joze, soora, nodes = []
            let nc=0, nj=0, ns=0
            
            items.forEach(item => {
                if(nc!==item.CourseId){
                    nodes.push({id:item.CourseId, text:`${item.CourseId} - ${item.Course}`, page:item.Safha, nodes:[]})
                    nc=item.CourseId
                    course=nodes[nodes.length-1]
                    nj=0
                }
                
                if(nj!==item.Joze){
                    course.nodes.push({id:item.Joze, text:`${item.Joze} - ${'جزء'}`, page:item.Safha, nodes:[]})
                    nj=item.Joze
                    joze = course.nodes[course.nodes.length-1]
                    ns=0
                }
                
                if(ns!==item.SooraId){
                    joze.nodes.push({id:item.SooraId, text:`${item.SooraId} - ${item.Soora}`, page:item.Safha, nodes:[]})
                    ns=item.SooraId
                    soora=joze.nodes[joze.nodes.length-1]
                }
                let title = `${item.Ila} - ${item.Min} (مقطع - ${item.Sahs})`
                soora.nodes.push({id:item.Safha, text:`${item.Safha}`, title:title})
            })
            Tree(nodes)

            //console.log('Tree',nodes); alert(items.length)
        }
    }, [loaded])    

    useLayoutEffect (()=>{  // like useEffect but synchronously after all DOM
        if(selected>0) {
            var nodes = document.querySelectorAll(`li`)
            nodes.forEach(function(node) {
                if (node.classList.contains('selected')) {
                    //e.target.parentElement
                } 
            })
        }
    },[])



    const onCollapse = (item) => {
        var ul = item.querySelector('ul');
        if (ul) {
            for (let i = 1; i < 4; i++) {
                if (item.classList.contains(`Node${i}`)) {

                    var nodes = item.querySelectorAll(`.Node${i+1}`)
                    nodes.forEach(function(node) {
                        if (node.classList.contains('collapsed')) {
                            node.classList.remove('collapsed');
    
                        } else {
                            node.classList.add('collapsed');
                        }
                    })
                }
            }                  
        }
        
        if (item.classList.contains('selected')) {
            item.classList.remove('selected')
        }
        else 
            item.classList.add('selected');

    }  
     
     
    const onNode = (e, id) => {
        
        if(e && id>0) {
            let level=e.target.id

            onCollapse(e.target.parentElement)

            document.cookie = `meSafha=${id}`

            onChange && onChange(id)
            
        }
    }
    
    const levels = ['Course', 'Joze', 'Soora', 'Safha']
    return (
        loaded ? 
        <div id="treeContainer" className="TreeView" >   
        
        <ul id="meTree" key={`meTree-${levels[0]}`} >
        { tree.map(course => (
            <li className="root Node1" value={course.id}>
                <span className="label" onClick={(e) => onNode(e,course.page)} id={`${levels[0]}-${course.id}`}>{course.text}</span>
            
            <ul key={`meTree-${levels[1]}`}>
            { course.nodes.map(joze => (
                <li className='Node2 collapsed'>
                    <span className="label" onClick={(e) => onNode(e,joze.page)} id={`${levels[1]}-${joze.id}`}>{joze.text}</span>

                <ul key={`meTree-${levels[2]}`}>
                { joze.nodes.map(soora => (
                    <li className='Node3 collapsed'>
                        <span className="label" onClick={(e) => onNode(e,soora.page)} id={`${levels[2]}-${soora.id}`}>{soora.text}</span>
                    
                    <ul key={`meTree-${levels[3]}`}>
                    { soora.nodes.map(safha => (
                        <li className={selected===safha.id ? 'Node4 selected' : 'Node4 collapsed'} >
                            <span className="label"  title={safha.title} onClick={(e) => onNode(e,safha.id)} id={`${levels[3]}-${safha.id}`}>{safha.text}</span>
                        </li>

                        ))
                    }                
                    </ul>
                    </li>
                    ))
                }                
                </ul>
                </li>
            ))
            }                
            </ul>
            
            </li>
        )) }
    </ul> 
    </div> 

    : <div>{entity} is loading...</div> 
    )
}

export default TreeView