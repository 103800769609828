import {NavLink} from 'react-router'
import {Navbar, Container} from 'react-bootstrap'

export const Navigation = (connected) => {


        return (
            
            <Container>

{ connected ?
    <Navbar>
        <Navbar.Collapse id="basic-navbar-nav" >
            <NavLink className="d-inline p-3 navbar-custom" to="/sentence">إحفظ</NavLink>
            <NavLink className="d-inline p-3 navbar-custom" to="/setting">إعدادات</NavLink>
        </Navbar.Collapse>
    </Navbar>
: null }
            </Container>

        )
}
