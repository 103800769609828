import React, {useState, useEffect, useLayoutEffect} from 'react'
import './Sentence.css'
import styled from 'styled-components'
import Button from '../Shared/Button'
import {meSplitter} from '../Shared/meGlobal'
import TreeView from "../meTree/TreeView"
import {ToNotify, Etat} from 'fast_inon'
import {DefaultControl, toTime} from '../Shared/ViewControl'
import jsPdf from 'jspdf'
import html2canvas from 'html2canvas'
import Api,{Get, Cookie} from '../Shared/Api'

const Sentence = ({props}) => {
    const path = 'https://moshaf.akademy.ma/waqafaat/'
    const [message, Message] = useState('')
    const [number, Number] = useState(-1)
    const [section, Section] = useState({Id:Cookie('meSection')??1, SooraId:0, Soora:'', Title:'', Items:[]})
    const [now, Now] = useState(new Date())
    const [loaded, Load] = useState(false)
    const [play, Play] = useState(false)
    const [select, Select] = useState(false)
    const [show, Show] = useState({i:0, n:0})
    const [tilaawa, Tilaawa] = useState(null)  // بِسۡمِ ٱللهِ ٱلرَّحۡمَـٰنِ ٱلرَّحِيمِ
    const [items, Items]= useState([])
    const audio = document?.getElementById("tilaawa")
    const [focus, Focus] = useState(0)

    const splitter = new meSplitter("Side", "PartContainer")

    useLayoutEffect (()=>{  // like useEffect but synchronously after all DOM
  
        splitter.selectorAll()

        if(section.Id>0) {
            onNode(section.Id)
        }

        Etat(`splitter ${splitter.items.length}`)

        Message('بِسۡمِ ٱللهِ ٱلرَّحۡمَـٰنِ ٱلرَّحِيمِ')
    },[])

    //const shuffle = (arr) => arr.sort(() => Math.random() - 0.5);

    useEffect(() => {
        if(loaded) {
            if(section.Items.length>0) {
                section.Items.forEach(item => {
                    if(!item?.Iqrae?.endsWith(".mp3")){
                        if(section?.SooraId>0 && item?.Tilaawa>0 && item?.Duration>0)
                            item["Iqrae"] = `${section.SooraId.toString().padStart(3,'0')}/${item.Tilaawa.toString().padStart(5,'0')}${(item.Tilaawa+item.Duration).toString().padStart(5,'0')}.mp3`
                        else 
                            item["Iqrae"] = ""
                    }
                })
                //let item=section.Items[1];   alert(`${item?.Id} - ${item?.Content} - ${item?.Iqrae}`)
            }
        }
        else if(props.connected){
            Get(`moshaf/ViewSections/1`,Items,null,null)

            Api.get(`moshaf/Part/${section.Id}`)
            .then(response => {
                //console.log('response',response.data); alert(response.data.Items.length)
                Section(response.data)

                //Items(response.data.Items)
                Load(true)
                Number(0)
            })
            .catch(err => console.error(err))

        }
    }, [loaded])
    
   
const onNext = (e, step=1) => {
    //alert(items.length)
    Select(false)
    items.forEach((item, index)=>{
        if(item.Id===section.Id) {
            let next = step>0 ? ((index+1<items.length) ? index+step : 0) : ((index-1>=0) ? index+step : items.length-1)
            let Id = items[next].Id

            document.cookie = `meSection=${Id}`
            Section({...section, Id})

            Load(false)
            return
        }
    })   
}

useEffect(() => {
    const interval = setInterval(() => {  
        Now(new Date())

    }, 1000)
    return () => clearInterval(interval)
}, []);

useEffect(()=>{
    //if(loaded && section.Items?.length>0) 
    {
        if(number>=0 && number<section.Items?.length) {
        
            const item = section.Items[number]
    
            if(item?.Duration>0) {
                Show({i:0, n:item.Duration})
    
                Tilaawa(`${path}${item.Iqrae}`)
            }
            else {
                Show({i:0, n:0})
                Tilaawa(null)
            }
        }
    }
},[number])

useEffect(()=>{
    if(props.connected && audio?.src) {
        if(tilaawa && tilaawa.endsWith('.mp3')) {
            //audio.src = tilaawa
            //audio.playbackRate=speed
            //alert(tilaawa)
            if( ! (number===0 && section.Id===1)) {
                audio.play()
            }
        }
        else {
            audio.pause()
        }
    }
},[tilaawa, audio])

useEffect(() => {
    //Message(`Audio is play=${play}`);
    if(props.connected && audio?.src) {
        select ? audio.play() : audio.pause()
    }
}, [select]);


useEffect(()=>{

    if(number===section.Items.length) {
        //Load(false)
        //Show({i:0, n:0})
        onNext()
    }
    else if(select) {
        if(show.n>0 && show.n===show.i) {
            //Number(number + 1)
        }
        else {
            Show({...show, i:show.i+1})
        }
    }
    
    Focus(focus+1)

    Etat(`Show ${number} (${show.i} / ${show.n} sec) ${toTime(focus)}`) 
    //console.log(show)
},[now])

const onScrollTop = () => {
    if(show.i>2) {
        //alert(show.i)
    }
}

const onNode = (id) => {
    Etat(`Node ${id}`)
}

const onSelect = (e, index) => {
    if(number!==index) {
        //alert(e.target.id)
        Number(index)
        Select(true)
        e.preventDefault()
    }
    else {
        Select(!select)
    }
}

const SavePdf = (name) => {
    const pdf = new jsPdf("p", "mm", "a5");
    const size = pdf.internal.pageSize;

    const element=document.querySelector('#SentenceDiv')
    html2canvas(element, { 
      useCORS: true,
    })
    .then((canvas)=>{
      const data = canvas.toDataURL('image/jpeg'); //console.log('ToData', data); alert(data.length)
      pdf.addImage(data, 'JPEG',0,0, size.width, size.height)
      pdf.save(`Moshaf-${name}.pdf`)
    })
    .catch((error) => {
      Message(error)
    })
  }
  
  const handleError = () => { 
    Message('Audio file cannot be loaded. Please check the source path.'); 
};    

    return (

props.connected ?
    <div className="Home" id="PartContainer" 
    onMouseDown={(e)=>{splitter.mouseDown(e)}} 
    onMouseMove={(e)=>{splitter.mouseMove(e)}} 
    onMouseUp={(e)=>{splitter.mouseUp(e)}}
    >

        <div className="side vside" id="SideSentence" style={props.layout ? {width:  '70%'} : {width:  '100%'}}>

            <audio id="tilaawa" src={tilaawa} 
                onPlay={()=>{Play(true)}} 
                onPause={()=>{Play(false)}} 
                onEnded={()=>{Number(number+1)}}
                onError={handleError}
                controls="controls" autobuffer="autobuffer" style={{height:'1rem', width: '-webkit-fill-available'}} />

            <SentenceDiv onScroll={onScrollTop} id="SentenceDiv">
            { loaded && section?.Items &&

                <>
                    <Question dangerouslySetInnerHTML={{ __html: `${section.Title} : ${section.Soora} - ${section.Aaya}` }}>    
                    </Question>                    
                    
<Options>

<table>
<tbody>

                        {section?.Items.map((item, index) => (
                            <tr key={index} >

        <td id={`jomla-${index}`} onClick={e => onSelect(e, index)}>
            <Option title={`الآية ${item.Aaya} (${item.Number})`} 
                dangerouslySetInnerHTML={loaded ? { __html: item.Content }:{ __html: null }} 
                
                style={{color:`rgba(${item.Font})`, 
                    fontWeight:(play && number===index)?'bold':'normal', 
                    backgroundColor:`rgba(${item.Back})`, borderColor:`rgba(${item.Bord})`}} >
            </Option>
            <div className='ProgressBar' style={(play && number===index)?{width:`${show.i*100/show.n}%`, backgroundColor:`rgba(${item.Bord})`}:{width:0}}></div>                        
        </td>

                            </tr>
                        ))}

</tbody>
</table>

</Options>

<footer>
    <p>{"https://moshaf.Akademy.mA"}</p>
</footer>


    <div id="save-button" data-html2canvas-ignore="true">
    <Button className="mr-2 NavButton" variant="warning" 
            style={{ left:0 }}
            onClick={e=>onNext(e,1)}
            ><i className="fa fa-arrow-left" title='مقطع جديد'></i>
        </Button>

        <Button className="mr-2 NavButton" variant="warning" 
            style={{ left:"94%" }}
            onClick={e=> onNext(e,-1)}
            ><i className="fa fa-arrow-right" title='مقطع جديد'></i>
        </Button>
        <Button onClick={e => {SavePdf(section.Title)}} className="Button">Save</Button>
    </div>
                </>

            }
        </SentenceDiv>
    </div>

    <div className="side vside" id="SideSection" style={props.layout ? {width:  '30%'} : {width: 0}}>
        <TreeView onChange={onNode} selected={section.Id} layout={props.layout} />
    </div>


<ToNotify 
    message={message}
    onClose={()=>{Message('')}}
/>
</div>
: 
    <DefaultControl />

    )
}

export default Sentence

const SentenceDiv = styled.div`
    text-align: center;
    padding: 1cm 1cm 0 0;
    width: 148mm;
    height: 210mm;
`;

const Options = styled.div`
    display: flex;
    direction : rtl;
    flex-direction: column;
    color: darkgreen;
    width: 90%;
    margin: 1em auto;
    
    @media screen and (min-width: 1180px) {
        width: 75%;
    }

`;

const Option = styled.button`
    display: block;
    border: 5px solid silver;
    border-radius: 15px;
    padding: 0.3rem 1rem;
    text-decoration: none;
    color: silver;
    background-color: #161A31;
    transition: 1s;
    font-size: 1.2rem;
    outline: none;
    user-select: none;
    margin: 0.2em;
    pointer-events: none;
    cursor: not-allowed;
    max-width: 100%;

    &:hover {
        font-weight: bold;
        opacity: 1;
    }
`;

const Question = styled.div`
    width: 100%;
    font-size: 1.8rem;
    margin: 1rem auto;
    color:rgb(6, 60, 66);
`;

